import http from "../servicio/Servicio"
class RegistroServicio{
    constructor() {
        this.model = 'licencia';
    }
    
    getAll= (data) => {
        return http.get("/api/"+this.model+"/all");
    }
    getAllByUser= (data) => {
        return http.post("/api/"+this.model+"/allbyuser",data);
    }
    create=data=>{
        return http.post("/api/"+this.model+"/create", data);
    }
    login=data=>{
        return http.post("/api/"+this.model+"/login", data);
    }
    
    logout=data=>{
        return http.post("/api/"+this.model+"/logout", data);
    }
    /*
    get15=data=> {
        return http.get("/planilla/get15/"+data);
    }
    login = data => {
        return http.post("/planilla/login", data);
    }
    registro(data) {
        return http.put("/planilla/registro", data);
    }    
    cerrar = data => {
        return http.post("/planilla/cerrar", data);
    }*/
}
export default new RegistroServicio();