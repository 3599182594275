import axios from "axios";
export default axios.create({
    baseURL: "https://licenciaserver.tatusol.com/",
    headers: {
      "Content-type": "application/json"
    }
    /*headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    }*/
  });
  