import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Menubar } from 'primereact/menubar';
import { Route, useLocation,Routes } from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import  Licencia from './componente/Licencia';
import  Login from './componente/Login';
import  Token from './componente/Token';
import servicio from './servicio/LicenciaServicio';
function App() {
  const navigate = useNavigate();
    const logout= ()=>{
        if(Token.getData()!==null){
            var data={token:Token.getData().registro.token};
            servicio.logout(data).then((res)=>{
                Token.clear();
                navigate('/');
            }).catch((error)=>{
                Token.clear();
                navigate('/');
            });
        }
        
    }
    var items = [
        {
            label: 'Licencia',
            icon: 'pi pi-fw pi-plus',
            command: () => {navigate('/licencia') }
        },        
        {
            label: 'Salir',
            icon: 'pi pi-fw pi-user-minus',     
            command: () => {logout()}       
        },
    ];
  var data=null;
  //const start = <img alt="logo" src="fdteusc.png" height="40" className="mr-2"></img>;
  var end = "";
  if (Token.getData() !== null) {
    data=Token.getData().registro;
    end = <p><b>Bienvenido: </b>{data.nombre}</p>
  } else {
    end = "";
  }
  return (

    <div className="App">
      <br/>

      {(data !== null) ? (
        <div className="card">
          <Menubar model={items} end={end} />
          <Routes>
            <Route path="/" element={<Licencia navigation={navigate}/>} />
            <Route path="/licencia" element={<Licencia navigation={navigate}/>} />
          </Routes>
        </div>
      ) : (
        <div className="card">

          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        </div>
      )}

    </div>
  );
}

export default App;
